<template>
  <div class="index">
    <div class="nav1">
      <div class="tit1">招聘网站配置</div>
    </div>
    <div class="nav2">
      <div class="myForm">
        <el-form :model="ruleForm" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-divider content-position="left">搜索条件长度配置</el-divider>
          <div style="display: flex">
            <div>
              <el-form-item label="关键词字数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_k_len"></el-input></el-form-item>
              <el-form-item label="关键词词数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_kword_len"></el-input></el-form-item>
              <el-form-item label="职位名称字数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_p_len"></el-input></el-form-item>
              <el-form-item label="职位名称词数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_pword_len"></el-input></el-form-item>
              <el-form-item label="公司名称字数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_c_len"></el-input></el-form-item>
              <el-form-item label="公司名称词数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_cword_len"></el-input></el-form-item>
              <el-form-item label="当前行业点选数量限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_h_len"></el-input></el-form-item>
              <el-form-item label="当前职位点选数量限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_j_len"></el-input></el-form-item>
              <el-form-item label="居住地点选数量限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_nowdq_len"></el-input></el-form-item>
              <el-form-item label="期望地点选数量限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_wantdq_len"></el-input></el-form-item>
              <el-form-item label="毕业院校字数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_school_len"></el-input></el-form-item>
              <el-form-item label="学科专业字数限制：猎聘"><el-input  size="small" v-model="ruleForm.liepin_discipline_len"></el-input></el-form-item>
            </div>

            <div>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_k_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_kword_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_p_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_pword_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_c_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_cword_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_h_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_j_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_nowdq_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_wantdq_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_school_len"></el-input></el-form-item>
              <el-form-item label="智联"><el-input  size="small" v-model="ruleForm.zhilian_discipline_len"></el-input></el-form-item>
            </div>
          </div>

          <el-divider content-position="left">查看简历额度配置</el-divider>
          <div style="display: flex">
            <el-form-item label="猎聘猎头版："><el-input  size="small" v-model="ruleForm.liepin_viewmax"></el-input></el-form-item>
            <el-form-item label="智联："><el-input  size="small" v-model="ruleForm.zhilian_viewmax"></el-input></el-form-item>
            <el-form-item label="猎聘企业版："><el-input  size="small" v-model="ruleForm.lpt_viewmax"></el-input></el-form-item>
            <el-form-item label="前程无忧："><el-input  size="small" v-model="ruleForm.job51_viewmax"></el-input></el-form-item>
          </div>
          <el-form-item>
            <el-button style="margin-top:10px" icon="el-icon-s-promotion" size="small" type="primary" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
<!--    应该是和上传图片相关的内容，displayN-> display:none-->
    <input type="file" name="companyLogo" id="file0" class="displayN" multiple="multiple" @change="companyLogo($event)"
      ref="fileInputList" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgFile: "",
      ruleForm: {
        liepin_viewmax: "",
        zhilian_viewmax: "",
        lpt_viewmax: "",
        job51_viewmax: "",
        liepin_k_len:"",
        liepin_kword_len:"",
        liepin_p_len:"",
        liepin_pword_len:"",
        liepin_c_len:"",
        liepin_cword_len:"",
        liepin_h_len:"",
        liepin_j_len:"",
        liepin_nowdq_len:"",
        liepin_wantdq_len:"",
        liepin_school_len:"",
        liepin_discipline_len:"",
        zhilian_k_len:"",
        zhilian_kword_len:"",
        zhilian_p_len:"",
        zhilian_pword_len:"",
        zhilian_c_len:"",
        zhilian_cword_len:"",
        zhilian_h_len:"",
        zhilian_j_len:"",
        zhilian_nowdq_len:"",
        zhilian_wantdq_len:"",
        zhilian_school_len:"",
        zhilian_discipline_len:"",
      },
      id: ""
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$api.edupeizhi_viewmax();
      this.ruleForm=res
    },
    // 提交
    async submitForm() {
      if (this.ruleForm.liepin_viewmax>=0&&this.ruleForm.liepin_viewmax<=5000){
        if (this.ruleForm.zhilian_viewmax>=0 && this.ruleForm.zhilian_viewmax<=5000){
          const res = await this.$api.editpeizhi_viewmax({
            liepin_viewmax: this.ruleForm.liepin_viewmax,
            zhilian_viewmax:this.ruleForm.zhilian_viewmax,
            lpt_viewmax:this.ruleForm.lpt_viewmax,
            job51_viewmax:this.ruleForm.job51_viewmax,
            liepin_k_len:this.ruleForm.liepin_k_len,
            liepin_kword_len:this.ruleForm.liepin_kword_len,
            liepin_p_len:this.ruleForm.liepin_p_len,
            liepin_pword_len:this.ruleForm.liepin_pword_len,
            liepin_c_len:this.ruleForm.liepin_c_len,
            liepin_cword_len:this.ruleForm.liepin_cword_len,
            liepin_h_len:this.ruleForm.liepin_h_len,
            liepin_j_len:this.ruleForm.liepin_j_len,
            liepin_nowdq_len:this.ruleForm.liepin_nowdq_len,
            liepin_wantdq_len:this.ruleForm.liepin_wantdq_len,
            liepin_school_len:this.ruleForm.liepin_school_len,
            liepin_discipline_len:this.ruleForm.liepin_discipline_len,
            zhilian_k_len:this.ruleForm.zhilian_k_len,
            zhilian_kword_len:this.ruleForm.zhilian_kword_len,
            zhilian_p_len:this.ruleForm.zhilian_p_len,
            zhilian_pword_len:this.ruleForm.zhilian_pword_len,
            zhilian_c_len:this.ruleForm.zhilian_c_len,
            zhilian_cword_len:this.ruleForm.zhilian_cword_len,
            zhilian_h_len:this.ruleForm.zhilian_h_len,
            zhilian_j_len:this.ruleForm.zhilian_j_len,
            zhilian_nowdq_len:this.ruleForm.zhilian_nowdq_len,
            zhilian_wantdq_len:this.ruleForm.zhilian_wantdq_len,
            zhilian_school_len:this.ruleForm.zhilian_school_len,
            zhilian_discipline_len:this.ruleForm.zhilian_discipline_len,
          });
          console.log(res);
          if (res.result == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
            this.getData()
          } else {
            this.$message.error(res.msg);
          }
        }else {
          //智联额度限制外
          this.$message.error("请确认智联额度范围(0~5000)");
          this.getData();
        }
      }else {
        //猎聘额度限制外
        this.$message.error("请确认猎聘额度范围(0~5000)");
        this.getData();
      }
    },
    // 上传图片
    companyList() {
      this.$refs.fileInputList.click();
    },
    // 删除图片
    delImg() {
      this.$set(this.ruleForm, "img", "");
    },
    //将文件转为blob类型
    readFileAsBuffer(file) {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64File = reader.result.replace(
            /^data:\w+\/\w+;base64,/,
            ""
          );
          resolve(new window.OSS.Buffer(base64File, "base64"));
        };
      });
    },
    async companyLogo(event) {
      const that = this;
      var file = event.target.files[0];
      var fileSize = file.size; //文件大小
      var filetType = file.type; //文件类型
      //创建文件读取对象
      // console.log(file);
      if (fileSize <= 10240 * 1024) {
        if (
          filetType == "image/png" ||
          filetType == "image/jpeg" ||
          filetType == "image/gif"
        ) {
          var file_re = await this.readFileAsBuffer(file);
          // console.log(this.imgFile);
          const res = await this.$api.aliyun_osssts()
          let client = new window.OSS.Wrapper({
            secure:true,
            region: res.region, //oss地址
            accessKeyId: res.accessKeyId, //ak
            accessKeySecret: res.accessKeySecret, //secret
            stsToken: res.stsToken,
            bucket: res.bucket, //oss名字
          });
          var imgtype = file.type.substr(6, 4);
          var store = `${new Date().getTime()}.${imgtype}`;
          console.log(store);
          client.put(store, file_re).then(() => {
            //这个结果就是url
            var a = client.signatureUrl(store);
            if (a.match('^http://')) {
              a = a.replace("http://", "https://")
            }
            this.oss_imgurl = a;
            console.log(a);
            this.$set(this.ruleForm, 'img', a)
          });
          that.$refs.fileInputList.value = "";
        } else {
          this.$message.error("图片格式不正确");
        }
      } else {
        this.$message.error("图片大小不正确");
      }
    }
}
};
</script>

<style lang="scss" scoped>
.index {}

.nav1 {
  margin: 0 -18px;
  background-color: #fff;
  padding: 20px 32px 0 40px;

  .tit1 {
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
  }
}

.nav2 {
  margin: 18px 0;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  .myForm {
    ::v-deep  .el-form-item__label {
      font-size: 12px;
    }

    ::v-deep  .el-form-item {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  .myImg {
    position: relative;
    width: 200px;
    height: 200px;
    display: inline-block;
    margin-right: 12px;

    .closeBtn {
      position: absolute;
      top: -6px;
      right: -4px;
      width: 20px;
      height: 20px;

      .el-button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ::v-deep  .image-slot {
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #fafafa;
      width: 198px;
      height: 198px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-icon-picture-outline {
        font-size: 20px;
      }
    }
  }
}

.displayN {
  display: none;
}
</style>
